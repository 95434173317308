<template>
    <div>
        <div class="ft20 cl-main ftw600">活动列表</div>

        <div class="mt20">
          <div class="form-search-box ">
            <a-form layout="inline">
              <a-form-item label="名称">
                <a-input v-model="search.name" placeholder="请输入活动名称"></a-input>
              </a-form-item>

              <a-form-model-item label="分类">
                <a-tree-select v-model="search.active_cid" style="width:140px" :tree-data="type_list" placeholder="请选择分类" ></a-tree-select>
              </a-form-model-item>

              <a-form-item label="站点">
                <div>
                  <a-select style="width: 180px" placeholder="全部站点" v-model="search.shop_id">
                    <a-select-option :value="0">全部站点</a-select-option>
                    <a-select-option :value="item.shop_id" :key="index"
                                     v-for="(item,index) in shop_list">{{ item.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-form-item>

              <a-form-model-item label="活动时间" >
                <a-range-picker
                    :show-time="{ format: 'HH:mm' }"
                    format="YYYY-MM-DD"
                    :placeholder="['活动开始时间', '活动结束时间']"
                    @ok="onOkAct"
                    @change="changeAct"
                />
              </a-form-model-item>

              <a-form-item>
                <a-button @click="searchAct" type="primary">查询</a-button>
                <a-button @click="cancelAct" class="ml10">取消</a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>

        <div class="mt20">
            <div class="bg-w">
                <div class="pd30">
                  <div class="wxb-table-gray">
                    <a-table rowKey="active_id" :columns="columns" :pagination="pagination" :data-source="datas" :loading="loading" @change="handleTableChange">
                      <div class="flex alcenter" slot="vip" slot-scope="record,index">
                        <img v-if="record.cover_img!=null" :src="record.cover_img" class="index-face" />
                        <div class="ml5" style="text-align: left;">
                          <div>{{record.name}}</div>
                          <div class="mt2 ft12">站点：{{record.site_name}}</div>
                        </div>
                      </div>

                      <div class="flex alcenter" slot="contacts" slot-scope="record,index">
                        <div class="ml5" style="text-align: left;">
                          <div>{{record.contacts}}</div>
                          <div>{{record.contacts_mobel}}</div>
                        </div>
                      </div>

                      <div class="flex alcenter center"  slot="type" slot-scope="type">
                        {{ type != null ? type.name : '暂无分类' }}
                      </div>

                      <div class="flex alcenter center" slot="status" slot-scope="record,index">
                        暂无分类
                      </div>

                      <div class="flex alcenter" slot="activetime" slot-scope="record,index">
                        <div class="ml5" style="text-align: left;">
                          <div>开始：{{record.start_time}}</div>
                          <div>结束：{{record.end_time}}</div>
                        </div>
                      </div>

                      <template slot="action" slot-scope="record,index">
                        <div class="flex center">
                          <a-dropdown placement="bottomRight">
                            <span class="more-act">
                              <i class="iconfont iconmore_gray"></i>
                            </span>
                            <a-menu slot="overlay">
                              <a-menu-item>
                                <a class="menu-act" href="javascript:;" @click="edit(record)">
                                  <span>详情</span>
                                </a>
                              </a-menu-item>
                            </a-menu>
                          </a-dropdown>
                        </div>
                      </template>

                    </a-table>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {listMixin} from '@/common/mixin/list';

export default {
    mixins: [listMixin],
    data() {
        return {
            loading: false,
            currentPage: Number(sessionStorage.getItem('adminActiveCurrentPage')) || 1,
            pagination: {
              current: Number(sessionStorage.getItem('adminActiveCurrentPage')) || 1, // 默认当前页数
              pageSize: 10, //每页中显示10条数据
              total: 0,
              onChange: (current) => {
                this.currentPage = current;
              }
            },
            type_list: [],
            shop_list: [],
            search: {
                name: '',
                active_cid: 0,
                shop_id: 0,
                status: 1,
                active_time: "",
            },
            columns: [
              {title: '编号',dataIndex: 'active_id',align: 'center',width:100},
              {title: '活动',key: 'name',align: 'left',width:250,scopedSlots: {customRender: 'vip'}},
              {title: '联系人',key: 'contacts',align: 'left',width:130,scopedSlots: {customRender: 'contacts'}},
              {title: '活动时间',key: 'activetime',align: 'left',width:230,scopedSlots: {customRender: 'activetime'}},
              {title: '所属分类', dataIndex: 'type', align: 'center', width: 160, scopedSlots: {customRender: 'type'}},
              {title: '状态',dataIndex: 'status_name',align: 'center'},
              {title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
            ],
            datas: [],
        }
    },
    methods: {
        getLists() {
            if (this.loading === true) return;
            this.loading = true;
            this.$http.api('platform/active/getActiveLists', {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                name: this.search.name,
                active_cid: this.search.active_cid,
                shop_id: this.search.shop_id,
                status: this.search.status,
            }).then(res => {
              this.pagination.total=res.total;
              this.pagination.showTotal=(total)=>"共"+res.total+"条";
              this.datas = res.list;
              this.type_list = res.type_list;
              this.shop_list = res.shop_list;
              this.loading = false;
            }).catch(res => {
                this.loading = false;
                console.log(res);
            })
        },
        edit(record){
          sessionStorage.setItem('adminActiveCurrentPage', this.currentPage);
          this.$router.push('activeAdmin/editActivity?id='+record.active_id);
        },
        onOkAct(value) {
          console.log('onOkAct: ', value);
        },
        changeAct(date,dateString){
          this.search.active_time = dateString;
        },
        handleTableChange(pagination, filters, sorter) {
          this.pagination.current = pagination.current;
          sessionStorage.setItem('adminActiveCurrentPage', pagination.current);
          this.getLists();
        },
    }
}
</script>

<style>
.index-face {
  width: 70px;
  height: 44px;
  border: 2px solid #FFFFFF;
}

.member-index-action {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: #FFFFFF;
  border: 1px solid #EBEDF5;
  text-align: center;
  line-height: 32px;
}
</style>
